import Vue from 'vue'
import Router from 'vue-router'
import login from '../pages/login/Login'
import firebase from 'firebase'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: 'Entrar',
      public: true
    },
    component: login
  },
  {
    path: '/login/',
    name: 'login',
    meta: {
      title: 'Entrar',
      public: true
    },
    component: login
  },
  {
    path: '/affiliates/',
    name: 'affiliates',
    meta: {
      title: 'Afiliados'
    },
    component: () => import(/* webpackChunkName: "Login" */ '../pages/affiliates/Affiliates')
  },
  {
    path: '/users/',
    name: 'users',
    meta: {
      title: 'Clientes'
    },
    component: () => import(/* webpackChunkName: "Login" */ '../pages/users/Users')
  },
  {
    path: '/leads/',
    name: 'leads',
    meta: {
      title: 'Leads'
    },
    component: () => import(/* webpackChunkName: "Login" */ '../pages/leads/Leads')
  },
  {
    path: '/home/',
    name: 'home',
    meta: {
      title: 'Resumo'
    },
    component: () => import(/* webpackChunkName: "Home" */ '../pages/home/Home')
  }
]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = 'Emissão Simples | ' + to.meta.title
  document.public = to.meta.public

  if (to.matched.some(record => !record.meta.public)) {
    if (!firebase.auth().currentUser) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }

  // if (!window.uid && !to.meta.public) {
  //   next({ name: 'login' })
  // } else {
  //   next()
  // }
})

export default router
