import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Firebase from './firebase'
import VueMask from 'v-mask'
import axios from 'axios'
import moment from 'moment'
import pagarme from 'pagarme'
import VueApexCharts from 'vue-apexcharts'
import firebase from 'firebase/app'
import './assets/scss/app.scss'
import 'popper.js'
import 'bootstrap'
import VueSmoothScroll from 'v-smooth-scroll'
import VueScrollTo from 'vue-scrollto'
import VueYouTubeEmbed from 'vue-youtube-embed'
import VCalendar from 'v-calendar'
import CookieLaw from 'vue-cookie-law'
import VueGtag from 'vue-gtag'
import VueCryptojs from 'vue-cryptojs'

let app
Vue.use(VueCryptojs)
Vue.use(CookieLaw)
Vue.use(VueYouTubeEmbed)
Vue.use(VueScrollTo)
Vue.use(Firebase)
Vue.use(VueMask)
Vue.use(VueApexCharts)
Vue.use(VueSmoothScroll)
Vue.use(VueScrollTo)
Vue.use(VCalendar)
Vue.component('apexchart', VueApexCharts)
Vue.prototype.$http = axios
Vue.prototype.$pagarme = pagarme
Vue.prototype.moment = moment
Vue.config.productionTip = false
Vue.prototype.$userPlan = ''
Vue.use(VueGtag, {
  config: { id: 'AW-722129369' }
})

firebase.apps[0].auth().onAuthStateChanged(user => {
  document.title = 'Emissão Simples'
  window.uid = user ? user.uid : null
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})
