<template>
  <div id="app">
    <base-spinner/>
    <router-view/>
  </div>
</template>

<script>
import BaseSpinner from './components/global/BaseSpinner'

export default {
  name: 'App',
  components: {
    BaseSpinner
  },
  mounted () {
    setTimeout(() => {
      this.$root.$emit('Spinner::hide')
    }, 150)
    // await this.$firebase.auth().onAuthStateChanged(user => {
    //   window.uid = user ? user.uid : null

    //   setTimeout(() => {
    //     this.$root.$emit('Spinner::hide')
    //   }, 1200)
    // })
  }
}
</script>
